<script>
    import EditItem from './edit_item.vue'
    import EditDir from './edit_dir.vue'

    export default {
        components: {EditItem, EditDir},
        data() {
            return {
                edit_type: 0,
                expandedKeys: [],
                item_list: [],
                item_id: 0,
                nav_show: true,
                item_info: {
                    id: 0,
                    name: "",
                    pid: 0,
                    type: 0,
                    content: "",
                    content_turn: "",
                    is_del: 2
                }
            };
        },
        watch: {
            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            }
        },
        mounted() {
            this.mountedDo()
        },
        methods: {


            toCreate(type) {
                this.edit_type = 1
                this.edit_info = type
                this.item_info = {
                    id: 0,
                    name: "",
                    pid: this.item_id,
                    type: type,
                    content: "",
                    content_turn: "",
                    is_del: 2
                }
            },
            toEdit() {
                this.edit_type = 0
                this.mountedDo();
            },
            updateData() {
                this.mountedDo()
            },
            getItemInfo() {
                if (this.item_id === 0) {
                    if (this.edit_type === 0) {
                        this.item_info = {
                            id: 0,
                            name: "",
                            pid: 0,
                            type: 0,
                            content: "",
                            content_turn: "",
                            is_del: 2
                        }
                    } else {
                        this.$router.push({
                            params: {
                                id: this.item_id
                            },
                            query: {
                                edit_type: 0
                            }
                        })
                    }
                } else {
                    this.$sa0.post({
                        url: this.$api('根据id获取委托单字段详情'),
                        data: {
                            id: this.item_id,
                        }
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.item_info = response.data.form_word
                                console.log(this.item_info)
                                this.expandedKeys = ((this.item_info.form_word_ids).toString()).split(',').map((item) => {
                                    return Number(item)
                                })
                                switch (this.item_info.type) {
                                    case 0:
                                    case 1:
                                    case 5:
                                    case 6:
                                    case 7:
                                        this.item_info.content_turn = this.item_info.content;
                                        break;
                                    case 2:
                                    case 3:
                                    case 4:
                                        this.item_info.content_turn = JSON.parse(this.item_info.content);
                                        break;
                                }
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                }
            },
            createDir() {
                this.$router.push({
                    params: {
                        id: 0
                    }
                })
            },
            mountedDo() {
                this.item_id = Number(this.$route.params.id ? this.$route.params.id : 0)
                this.edit_type = Number(this.$route.query.edit_type ? this.$route.query.edit_type : 0)
                this.getItemList()
                this.getItemInfo()
            },
            itemClick(e) {
                let id = e[0] ? e[0] : this.item_id
                if (Number(id) === Number(this.$route.params.id)) {
                    this.mountedDo()
                } else {
                    this.$router.push({
                        params: {
                            id: id
                        },
                        query: {
                            edit_type: 0
                        }
                    })
                }
            },
            getItemList() {
                this.$sa0.post({
                    url: this.$api('获取委托单字段分级列表'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.item_list = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            toReload() {
                this.getItemList()
            }

        },
    };
</script>
<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="bgc sidebar">
                <div class="samplelefttit">
                    委托单字段列表
                </div>
                <div class="sidebarmain">
                    <a-tree :tree-data="item_list" :expandedKeys.sync="expandedKeys" :replaceFields="{children:'child', title:'name', key:'id' }" :show-line="true" @select="itemClick">
                        <a-icon slot="icon" type="carry-out"/>
                    </a-tree>
                </div>
                <div class="addbutton">
                    <div class="addbuttonbtn" @click="createDir()">
                        添加分类
                    </div>
                </div>
            </div>
            <div class="wrap-container">
                <div class="createsmaplewrap"></div>
                <div class="createsmaplewrap">
                    <div class="createsmaple">

                        <EditDir :create="toCreate" :edit="toEdit" :update="updateData" v-if="Number(item_info.type) === 0" :info="item_info"></EditDir>
                        <EditItem :toReload="toReload" :create="toCreate" :edit="toEdit" :update="updateData" v-if="Number(item_info.type) !== 0" :info="item_info"></EditItem>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<style scoped>
    .bgc{
        margin-right: 20px;
        border: 1px solid #eeeeee;
        }

    .samplelefttit{
        background: #f9f9f9;
        padding: 10px;
        border-bottom: 1px solid #eeeeee;
        font-weight: bold
        }


    .createsmaple{
        display: flex;
        justify-content: space-between
        }

    .wraper{ width: 100%; height: 100%; display: flex;}

    .wrap-mainer{ flex: 1;display: flex; }

    .sidebar{ width: 250px; display: flex; flex-direction: column}

    .sidebarmain{ flex: 1; overflow-y: auto}

    .wrap-container{ flex: 1; overflow-y: auto}

    .addbutton{ width: 100%; text-align: center; bottom: 0; height: 40px; background: #1890ff}

    .addbuttonbtn{ width: 100%; color: #ffffff; line-height: 40px; cursor: pointer; height: 40px}
</style>
